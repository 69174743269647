import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "~/utils/cn";

const blurEllipseVariants = cva("absolute w-full h-full rounded-[50%] opacity-10 blur-[100px]", {
  variants: {
    color: {
      primary: "bg-primary",
      brand1: "bg-brand-1",
      brand2: "bg-brand-2",
      brand3: "bg-brand-3",
      brand4: "bg-brand-4",
      brand5: "bg-brand-5",
      chainsPrimary: "bg-chains-primary",
      chainsSecondary: "bg-chains-secondary",
      chainsTertiary: "bg-chains-tertiary",
      analystsPrimary: "bg-analysts-primary",
      analystsSecondary: "bg-analysts-secondary",
      analystsTertiary: "bg-analysts-tertiary",
      questorsPrimary: "bg-questors-primary",
      questorsSecondary: "bg-questors-secondary",
      questorsTertiary: "bg-questors-tertiary",
    },
    size: {
      sm: "scale-75",
      md: "scale-100",
      lg: "scale-125",
    },
  },
  defaultVariants: {
    color: "brand1",
    size: "md",
  },
});

interface BlurEllipseProps extends VariantProps<typeof blurEllipseVariants> {
  className?: string;
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
}

export const BlurEllipse = ({ color, size, className, top, right, bottom, left }: BlurEllipseProps) => {
  return <div className={cn(blurEllipseVariants({ color, size }), className)} style={{ top, right, bottom, left }} />;
};
