import { ButtonArrow } from "@fscrypto/flip-kit/button-arrow";
import { SectionContainer } from "./section-container";
import type { Gradient } from "./section-title";
import { SplitSectionTitle } from "./split-section-title";
import { SectionIntro } from "./section-intro";
import type { BrandSquareColor } from "@fscrypto/flip-kit/brand-square";

interface ImageGridSectionProps {
  theme: "light" | "dark" | "white";
  firstTitle: string;
  secondTitle: string;
  buttonText?: string;
  imageUrl: string;
  imageAlt: string;
  introTitle?: string;
  introDescription?: React.ReactNode;
  introColor?: BrandSquareColor;
  backgroundSlot?: React.ReactNode;
  gradient?: Gradient;
  buttonLinkUrl?: string;
}

export const SingleImageSection = ({
  theme,
  firstTitle,
  secondTitle,
  gradient,
  buttonText,
  imageUrl,
  imageAlt,
  introTitle,
  introDescription,
  introColor,
  backgroundSlot,
  buttonLinkUrl,
}: ImageGridSectionProps) => {
  return (
    <SectionContainer theme={theme}>
      {backgroundSlot}
      <div className="py-10">
        <SplitSectionTitle first={firstTitle} second={secondTitle} gradient={gradient} />
        {introTitle && introDescription && introColor && (
          <SectionIntro labelText={introTitle} paragraphText={introDescription} dotColor={introColor} />
        )}
        <div className="flex flex-col items-center pt-16 pb-12 lg:px-20">
          <img src={imageUrl} alt={imageAlt} className="w-full" />
          {buttonText && (
            <ButtonArrow fullWidth>
              <a href={buttonLinkUrl} className="w-full">
                {buttonText}
              </a>
            </ButtonArrow>
          )}
        </div>
      </div>
    </SectionContainer>
  );
};
