import { Text } from "@fscrypto/flip-kit/text";
import { cn } from "~/utils/cn";

export type Gradient = "explorer" | "analyst" | "partner";

export const SplitSectionTitle = ({
  first,
  second,
  gradient,
}: {
  first: string;
  second: string;
  gradient?: Gradient;
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex justify-start">
        <SectionTitleLine text={first} gradient={gradient} />
      </div>
      <div className="w-full flex justify-end">
        <SectionTitleLine text={second} gradient={gradient} />
      </div>
    </div>
  );
};

const SectionTitleLine = ({ text, gradient }: { text: string; gradient?: Gradient }) => {
  return (
    // note: this is wrapped in a div to make sure the gradient is applied to the entire text
    // the gradient uses its parent to calulate the color position it seems.
    <div>
      <Text
        variant="site-headline"
        as="h2"
        className={cn("uppercase", {
          "text-gradient-explorer": gradient === "explorer",
          "text-gradient-analyst": gradient === "analyst",
          "text-gradient-partner": gradient === "partner",
        })}
      >
        {text}
      </Text>
    </div>
  );
};
