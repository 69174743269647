import { Text } from "@fscrypto/flip-kit/text";
import { cn } from "~/utils/cn";

export type Gradient = "explorer" | "analyst" | "partner";

export const SectionTitle = ({
  first,
  second,
  third,
  firstGradient,
  secondGradient,
  thirdGradient,
  description,
}: {
  first: string;
  second?: string;
  third?: string;
  firstGradient?: Gradient;
  secondGradient?: Gradient;
  thirdGradient?: Gradient;
  description?: string;
}) => {
  return (
    <div className="flex flex-col justify-start">
      <SectionTitleLine text={first} gradient={firstGradient} />
      {second && <SectionTitleLine text={second} gradient={secondGradient} />}
      {third && <SectionTitleLine text={third} gradient={thirdGradient} />}
      {/* in mobile render the description below the title */}
      <div className="sm:hidden w-[164px]">
        <Text variant="button">{description}</Text>
      </div>
    </div>
  );
};

const SectionTitleLine = ({ text, gradient }: { text: string; gradient?: Gradient }) => {
  return (
    // note: this is wrapped in a div to make sure the gradient is applied to the entire text
    // the gradient uses its parent to calulate the color position it seems.
    <div>
      <Text
        variant="section-headline"
        as="h2"
        className={cn("uppercase", {
          "text-gradient-explorer": gradient === "explorer",
          "text-gradient-analyst": gradient === "analyst",
          "text-gradient-partner": gradient === "partner",
        })}
      >
        {text}
      </Text>
    </div>
  );
};
