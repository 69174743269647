import type { LoaderFunctionArgs } from "@remix-run/node";
import type { MetaFunction } from "@remix-run/react";
import { createRequestCaller } from "~/.server/router";
import { json, useLoaderData } from "~/remix";
import { HorizontalCardSection } from "./horizontal-cards-section";
import { SingleImageSection } from "./single-image-section";
import { GradientCardSection } from "./gradient-card-section";
import { Faq } from "~/components/faq-section";
import { ImageCardSection } from "./image-card-section";
import { QuestsSection } from "./quests-section";
import PrizePoolBanner from "~/components/quest-users";
import { BlurEllipse } from "~/components/ui/blur-ellipse";
import { useNavigate } from "@remix-run/react";
import { buildMetaTags } from "@fscrypto/domain";

export const meta: MetaFunction = () => {
  return buildMetaTags({
    title: "Earn with Quests | Flipside Crypto",
    description:
      "Embark on blockchain quests, earn tokens for your accomplishments, and grow your onchain footprint. Join Flipside's community of crypto explorers to shape the onchain economy.",
    canonicalUrl: "https://flipsidecrypto.xyz/earn",
    ogImageUrl: "https://res.cloudinary.com/dsr37ut2z/image/upload/v1731516801/assets/og-images/Earn_1.png",
    twitterImageUrl: "https://res.cloudinary.com/dsr37ut2z/image/upload/v1731516801/assets/og-images/Earn_2.png",
  });
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const caller = await createRequestCaller(request);

  const questsPromise = caller.public.quests.all({});

  const userPromise = caller.public.me();

  const [quests, user] = await Promise.all([questsPromise, userPromise]);

  return json({ quests, user });
};

export default function Index() {
  const navigate = useNavigate();
  const { quests, user } = useLoaderData<typeof loader>();

  return (
    <div className="bg-bg-primary dark">
      <QuestsSection
        quests={quests}
        theme="light"
        firstTitle="Explore"
        secondTitle="Trending"
        thirdTitle="Quests"
        thirdGradient="explorer"
      />
      <HorizontalCardSection
        theme="light"
        firstTitle="Conquer"
        secondTitle="Quests"
        thirdTitle="To LEVEL UP"
        thirdGradient="explorer"
        labelText="How it works"
        dotColor="questors-primary"
        paragraphText="Gain experience and grow your wallet as you journey through Flipside quests."
        cards={[
          {
            title: "Connect your Wallet",
            description:
              "Link your crypto wallet to start your journey and seamlessly track your progress across all quests.",
          },
          {
            title: "Explore Quests",
            description:
              "Explore a diverse range of quests, each designed to enhance your blockchain knowledge and offer crypto rewards.",
          },
          {
            title: "Complete the steps",
            description:
              "Perform specific onchain actions required for each quest. Gain experience points to level up and unlock new opportunities.",
          },
          {
            title: "Claim your crypto",
            description:
              "Receive crypto rewards instantly upon quest completion. As you level up, access higher-tier quests with more substantial rewards.",
          },
        ]}
      />
      <SingleImageSection
        theme="dark"
        firstTitle="Enter Grail,"
        secondTitle="Compete for glory"
        gradient="explorer"
        buttonText="Start Playing"
        buttonLinkUrl="https://flipsidecrypto.xyz/grail/near/s2/final"
        imageUrl="https://res.cloudinary.com/dsr37ut2z/image/upload/v1731083578/fsc-marketing/Grail_Visual.png"
        imageAlt="explorers hero visual"
        introTitle="Start your journey"
        introColor="brand-5"
        introDescription={
          <div className="flex flex-col gap-y-4">
            Dive into Grail—a high-stakes arena where you’ll wield tokens and onchain skills in an epic tournament for
            coveted crypto rewards.
            <PrizePoolBanner
              avatarUrls={[
                "https://randomuser.me/api/portraits/med/women/44.jpg",
                "https://randomuser.me/api/portraits/med/women/80.jpg",
                "https://randomuser.me/api/portraits/med/men/83.jpg",
              ]}
              competitorCount={2_159}
              prizeAmount={150_000}
            />
          </div>
        }
        backgroundSlot={
          <>
            <BlurEllipse color="brand2" size="sm" top="5%" right="15%" />
            <BlurEllipse color="brand4" size="sm" top="25%" left="20%" />
          </>
        }
      />
      <GradientCardSection
        theme="dark"
        title="Embark on an epic onchain odyssey."
        subtitle="Ready to claim your first reward?"
        buttonText="View all quests"
        linkTo="/all"
        gradient="explorer"
        buttonArrowIconClassName="-rotate-45"
      />
      <div className="px-10">
        <Faq />
      </div>
      {!user && (
        <ImageCardSection
          theme="dark"
          title1="YOUR EARNING"
          title2="JOURNEY IS ABOUT"
          title3="TO BEGIN"
          backgroundImage="https://res.cloudinary.com/dsr37ut2z/image/upload/v1729477352/fsc-marketing/Background_image_-_orange_gradient.jpg"
          buttonText="Sign up"
          externalLinkTo="/home/login?screen_hint=signup"
        />
      )}
    </div>
  );
}
