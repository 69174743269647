import { ButtonArrow } from "@fscrypto/flip-kit/button-arrow";
import { Text } from "@fscrypto/flip-kit/text";
import { Link } from "@remix-run/react";
import { cn } from "~/utils/cn";

interface GradientCardSectionProps {
  title: string;
  subtitle: string;
  buttonText: string;
  theme: "light" | "dark";
  gradient: "analyst" | "chains" | "explorer";
  buttonArrowIconClassName?: string;
  linkTo: string;
}

export const GradientCardSection = ({
  title,
  subtitle,
  buttonText,
  theme,
  gradient,
  buttonArrowIconClassName,
  linkTo,
}: GradientCardSectionProps) => {
  return (
    <div className={cn("p-10 bg-bg-primary", { dark: theme === "dark", light: theme === "light" })}>
      <div
        className={cn("h-[280px] w-full flex flex-col justify-between rounded-lg p-10", {
          "bg-gradient-analyst": gradient === "analyst",
          "bg-gradient-partner": gradient === "chains",
          "bg-gradient-explorer": gradient === "explorer",
        })}
      >
        <div className="flex flex-col">
          <Text variant="body-lg" color="text-white">
            {title}
          </Text>
          <Text variant="body-lg" color="text-white">
            {subtitle}
          </Text>
        </div>
        <Link to={linkTo}>
          <ButtonArrow size="link" className="text-text-white" arrowClassName={buttonArrowIconClassName}>
            {buttonText}
          </ButtonArrow>
        </Link>
      </div>
    </div>
  );
};
