import { SectionIntro } from "./section-intro";
import { SectionContainer } from "./section-container";
import { type Gradient, SectionTitle } from "./section-title";
import { Text } from "@fscrypto/flip-kit/text";

import type { BrandSquareColor } from "@fscrypto/flip-kit/brand-square";

interface CardData {
  title: string;
  description: string;
  buttonText?: string;
}

export const HorizontalCardSection = ({
  theme,
  labelText,
  paragraphText,
  dotColor,
  firstTitle,
  secondTitle,
  thirdTitle,
  cards,
  backgroundImageUrl,
  backgroundImageAlt,
  thirdGradient,
}: {
  theme: "light" | "dark";
  labelText: string;
  paragraphText: string;
  dotColor: BrandSquareColor;
  firstTitle: string;
  secondTitle?: string;
  thirdTitle?: string;
  cards: CardData[];
  backgroundImageUrl?: string;
  backgroundImageAlt?: string;
  thirdGradient?: Gradient;
}) => {
  return (
    <div className="-mt-16">
      <SectionContainer
        theme={theme ?? "gray"}
        bottomImageUrl={backgroundImageUrl}
        bottomImageAlt={backgroundImageAlt}
        floatSlot={
          <div className="absolute bottom-0 right-0">
            <img
              src="https://res.cloudinary.com/dsr37ut2z/image/upload/v1730734096/marketing-app/svgs/ellipse.svg"
              alt="ellipse"
              className="opacity-30 filter blur-[1px]"
            />
          </div>
        }
      >
        <div className="pt-10 relative z-10">
          <SectionTitle first={firstTitle} second={secondTitle} third={thirdTitle} thirdGradient={thirdGradient} />
          <SectionIntro labelText={labelText} paragraphText={paragraphText} dotColor={dotColor} />
          <HorizontalCardGrid cards={cards} />
        </div>
      </SectionContainer>
    </div>
  );
};

const HorizontalCardGrid = ({ cards }: { cards: CardData[] }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 min-h-[368px] mt-[64px] mb-8">
      {cards.map((c, index) => (
        <HorizontalCard key={c.title} {...c} index={index} />
      ))}
    </div>
  );
};

interface HorizontalCardProps {
  title: string;
  description: string;
  index: number;
}

const HorizontalCard = ({ title, description, index }: HorizontalCardProps) => {
  return (
    <div className="bg-bg-secondary rounded-md p-8 flex flex-col">
      <div className="flex flex-col gap-y-4">
        <Text color="text-tertiary" variant={"body-lg"}>
          {`0${index + 1}.`}
        </Text>
        <Text variant="body-lg">{title}</Text>
      </div>
      <div className="flex-grow" />
      <div className="flex flex-col justify-end h-[80px]">
        <Text color="text-tertiary">{description}</Text>
      </div>
    </div>
  );
};
