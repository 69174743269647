import {} from "@fscrypto/flip-kit/table";
import { SectionIntro } from "./section-intro";
import { ButtonArrow } from "@fscrypto/flip-kit/button-arrow";
import { SectionContainer } from "./section-container";
import { type Gradient, SectionTitle } from "./section-title";
import { QuestTable } from "~/components/quest-table";
import type { QuestWithMeta } from "@fscrypto/domain/earn";
import { Link } from "@remix-run/react";

export const QuestsSection = ({
  quests,
  theme,
  firstTitle,
  secondTitle,
  thirdTitle,
  firstGradient,
  secondGradient,
  thirdGradient,
}: {
  quests: QuestWithMeta[];
  theme: "light" | "dark";
  firstTitle?: string;
  secondTitle?: string;
  thirdTitle?: string;
  firstGradient?: Gradient;
  secondGradient?: Gradient;
  thirdGradient?: Gradient;
}) => {
  return (
    <SectionContainer theme={"white"} paddingBottom={24} rounded={false}>
      {firstTitle && (
        <div className="pt-10">
          <SectionTitle
            first={firstTitle}
            second={secondTitle}
            third={thirdTitle}
            firstGradient={firstGradient}
            secondGradient={secondGradient}
            thirdGradient={thirdGradient}
          />
        </div>
      )}
      <SectionIntro
        labelText="Start earning today"
        paragraphText="Earn tokens by exploring and engaging in blockchain ecosystems."
        dotColor="questors-primary"
      />
      <QuestTable quests={quests} />
      <Link to="/all" className="contents">
        <ButtonArrow fullWidth className="mt-10">
          View all quests
        </ButtonArrow>
      </Link>
    </SectionContainer>
  );
};
