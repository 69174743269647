import { ButtonArrow } from "@fscrypto/flip-kit/button-arrow";
import { Text } from "@fscrypto/flip-kit/text";
import { cn } from "~/utils/cn";

interface ImageCardSectionProps {
  title1: string;
  title2: string;
  title3: string;
  buttonText: string;
  theme: "light" | "dark";
  backgroundImage: string;
  externalLinkTo: string;
}

export const ImageCardSection = ({
  title1,
  title2,
  title3,
  buttonText,
  theme,
  backgroundImage,
  externalLinkTo: linkTo,
}: ImageCardSectionProps) => {
  return (
    <div className={cn("p-10 bg-bg-primary", { dark: theme === "dark", light: theme === "light" })}>
      <div
        className="h-[674px] w-full flex flex-col justify-between rounded-lg p-10 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="flex flex-col">
          <Text variant="section-headline-sm" color="text-white" className="uppercase">
            {title1}
          </Text>
          <Text variant="section-headline-sm" color="text-white" className="uppercase">
            {title2}
          </Text>
          <Text variant="section-headline-sm" color="text-white" className="uppercase">
            {title3}
          </Text>
        </div>
        <a href={linkTo}>
          <ButtonArrow className="text-text-white light w-[400px]">{buttonText}</ButtonArrow>
        </a>
      </div>
    </div>
  );
};
