import type { ReactNode } from "react";
import { cn } from "~/utils/cn";

export const SectionContainer = ({
  children,
  theme,
  bottomImageUrl,
  bottomImageAlt,
  floatSlot,
  paddingBottom,
  paddingX = true,
  rounded = true,
}: {
  children: ReactNode;
  theme: "light" | "dark" | "white" | "gray";
  bottomImageUrl?: string;
  bottomImageAlt?: string;
  floatSlot?: ReactNode;
  paddingBottom?: 10 | 24;
  paddingX?: boolean;
  rounded?: boolean;
}) => {
  return (
    <div
      className={cn("w-full justify-center flex bg-bg-primary relative", {
        light: theme === "light" || theme === "white" || theme === "gray",
        dark: theme === "dark",
        "bg-white": theme === "white",
        "bg-primary": theme === "gray",
        "rounded-2xl": rounded,
        "pb-10": paddingBottom === 10,
        "pb-24": paddingBottom === 24,
        "px-5 md:px-10": paddingX,
      })}
    >
      <div className="w-full flex justify-center relative flex-col">{children}</div>
      {bottomImageUrl && (
        <img src={bottomImageUrl} alt={bottomImageAlt} className="absolute bottom-0 right-0 z-0 w-full" />
      )}
      {floatSlot ?? null}
    </div>
  );
};
