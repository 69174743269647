import { Text } from "@fscrypto/flip-kit/text";
import { LogoIcon } from "@fscrypto/flip-kit/logo";

interface PrizePoolBannerProps {
  avatarUrls: string[];
  competitorCount?: number;
  prizeAmount?: number;
}

export default function PrizePoolBanner({
  avatarUrls,
  competitorCount = 12100,
  prizeAmount = 100000,
}: PrizePoolBannerProps) {
  const formattedCompetitors = `${(competitorCount / 1000).toFixed(1)}k`;
  const formattedPrize = `$${prizeAmount.toLocaleString()}`;

  return (
    <div className="text-white py-3 flex w-full">
      <div className="flex items-center gap-3 mr-8">
        <div className="flex -space-x-3">
          {avatarUrls.map((url, index) => (
            <img
              key={url}
              src={url}
              alt={`Competitor ${index + 1}`}
              className="w-8 h-8 rounded-full border-2 border-white"
            />
          ))}
        </div>
        <Text weight="medium" size="sm" className="tracking-wide">
          {formattedCompetitors} competing for the
        </Text>
      </div>

      <div className="flex items-center gap-2">
        <div className="h-8 w-8">
          <LogoIcon />
        </div>
        <Text weight="medium" size="sm" className="tracking-wide">
          {formattedPrize} Prize Pool
        </Text>
      </div>
    </div>
  );
}
