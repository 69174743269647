import { Text } from "@fscrypto/flip-kit/text";
import type { ReactNode } from "react";
import { cn } from "~/utils/cn";
import { BrandSquare, type BrandSquareColor } from "@fscrypto/flip-kit/brand-square";

interface SectionIntroProps {
  labelText?: string;
  paragraphText: React.ReactNode;
  dotColor?: BrandSquareColor;
  paddingX?: 10 | 24;
}

export const SectionIntro = ({ labelText, paragraphText, dotColor, paddingX }: SectionIntroProps) => {
  return (
    <SectionIntroContainer paddingX={paddingX}>
      <SectionIntroContent>
        <SectionIntroLabel labelText={labelText} dotColor={dotColor} />
        <SectionIntroParagraph paragraphText={paragraphText} />
      </SectionIntroContent>
    </SectionIntroContainer>
  );
};

const SectionIntroContainer = ({ children, paddingX }: { children: ReactNode; paddingX?: 10 | 24 }) => {
  return (
    <div
      className={cn(
        "w-full flex relative flex-col lg:flex-row gap-y-6",
        "lg:pt-32 pb-8 pt-16 sm:pt-32",
        "md:justify-between items-start",
        {
          "px-10": paddingX === 10,
        },
      )}
    >
      {children}
    </div>
  );
};

const SectionIntroContent = ({ children }: { children: ReactNode }) => {
  return <div className="w-full flex flex-col sm:flex-row gap-4 justify-between items-start">{children}</div>;
};

interface SectionIntroLabelProps {
  labelText?: string;
  dotColor?: BrandSquareColor;
}

export const SectionIntroLabel = ({ labelText, dotColor }: SectionIntroLabelProps) => {
  if (!labelText) return <div />;
  return (
    <div className="w-full justify-start flex items-center gap-x-2">
      <BrandSquare color={dotColor ?? "brand-1"} />
      <Text as="h2">{labelText}</Text>
    </div>
  );
};

interface SectionIntroParagraphProps {
  paragraphText: React.ReactNode;
}

const SectionIntroParagraph = ({ paragraphText }: SectionIntroParagraphProps) => {
  return <Text variant="body-xl">{paragraphText}</Text>;
};
